





















import {Component, Mixins, Watch} from 'vue-property-decorator'
import AddButton from '@/general/components/common/AddButton.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import {Header} from '@/general/models/Vuetify'
import Tabs from '@/general/components/common/Tabs.vue'
import PlanningService from "@/modules/planning/PlanningService";
import {ListPlannings_ListPlannings, ListPlannings_ListPlannings_items} from "@/generated/ListPlannings";
import {VehicleType} from "@/generated/globalTypes";

@Component({
  components: {
    Tabs,
    InfoCard,
    AddButton
  }
})
export default class Overview extends Mixins(PlanningService) {
  finished: boolean
  show = true
  plannings: ListPlannings_ListPlannings[] = []

  get headers() {
    return [
      new Header('shipment.fields.createdAt', 'createdAt'),
      new Header('shipment.fields.updatedAt', 'updatedAt'),
      new Header('common.identifier', 'identifier'),
      new Header('ecmr.fields.license', 'licensePlate'),
      new Header('user.single', 'appUser'),
    ]
  }

  get service() {
    this.finished = Boolean(JSON.parse((this.$route.query.finished as string) || 'false'))
    if (this.finished) {
      return this.getFinishedPlanningOverview
    } else {
      return this.getPlanningOverview
    }
  }

  created() {
    this.watchFinished()
  }

  @Watch('$route.query.finished')
  watchFinished() {
    this.finished = Boolean(JSON.parse((this.$route.query.finished as string) || 'false'))
    this.rerender()
  }

  rerender() {
    this.show = false
    this.$nextTick(() => {
      this.show = true
    })
  }

  getLicensePlate(planning: ListPlannings_ListPlannings_items): string {
    return planning.vehicles.find(vehicle => vehicle.type === VehicleType.TRUCK)?.licensePlate ? planning.vehicles.find(vehicle => vehicle.type === VehicleType.TRUCK)!!.licensePlate!! : ''
  }
}
